// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
//import "bootstrap"
//import * as bootstrap from "bootstrap"
import "./src/add_jquery"
import "./src/add_datatables.net-bs5"
import "bootstrap-datepicker/js/bootstrap-datepicker"
// import "./controllers/application"
// import "./controllers/clipboard_controller"
// import "./controllers/index"
// import "./clipboard_copy"

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
     laman: String
  }
  connect(){
       this.originalText = this.element.textContent
       console.log(this.lamanValue)
  }
  copy(){
    navigator.clipboard.writeText(this.lamanValue).then(
        () => {
             this.element.textContent = "Copied!"
             setTimeout(() =>{

                    this.element.textContent  = "Copy"

             }, 1000)
        },
        ()=> {
           alert("Failed to copy to clipboard")
        }
    );
  }
 }
